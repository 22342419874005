<template>
    <div>
        <section class="relative leading-7 text-gray-900 bg-white border-solid pb-8">
            <div class="box-border mx-auto border-solid lg:pl-8 max-w-7xl">
                <div class="flex flex-col items-center leading-7 text-gray-900 border-0 border-gray-200 lg:flex-row">
                    <div
                        class="box-border flex flex-col justify-center w-full h-full p-8 text-gray-900 border-solid lg:w-1/2 md:p-16 lg:p-0 lg:pl-10 lg:pr-20">
                        <h2 class="m-0 text-3xl font-medium leading-tight tracking-tight text-left text-black sm:text-4xl md:text-4xl">
                            How to setup metamask</h2>
                        <p class="mt-2 text-xl text-left border-0 border-gray-200 sm:text-xl">A browser wallet
                            extension</p>
                        <div
                            class="grid gap-4 mt-8 leading-7 border-0 border-gray-200 sm:mt-10 sm:gap-6 lg:mt-12 lg:gap-8">
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full">
                                    <p class="box-border m-0 text-xl text-white border-solid">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"/>
                                        </svg>
                                    </p>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">
                                        Installing metamask</h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">
                                        Installing metamask is easy and fast. It takes only 1 minute to set up!</p>
                                </div>
                            </div>
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full">
                                    <p class="box-border m-0 text-xl text-white border-solid">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"/>
                                        </svg>
                                    </p>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">
                                        Connecting to Binance Smart Chain</h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">A
                                        connection between metamask and Binance Smart Chain Network is required, in
                                        order to transfer and use funds on the BSC Network.</p>
                                </div>
                            </div>
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full">
                                    <p class="box-border m-0 text-xl text-white border-solid">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"/>
                                        </svg>
                                    </p>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">
                                        Adding funds</h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">
                                        Adding funds to your Metamask wallet can be done with ease. Transfering funds is
                                        even easier!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="w-full h-full overflow-hidden leading-7 text-gray-900 border-0 border-gray-200 lg:w-1/2 rounded-lg shadow-lg">
                        <img src="https://www.ledger.com/wp-content/uploads/2019/06/assets_logo_metamask.jpg"
                             class="object-cover w-full h-full" alt="EPZaH35">
                    </div>
                </div>
            </div>
        </section>
        <middle-banner/>
        <section class="py-20 bg-white 0">
            <div class="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12 pb-10">
                <div class="relative">
                    <h2 class="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl pt-5">How to setup
                        metamask</h2>
                    <p class="w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700 intro sm:max-w-3xl">Download
                        metamask <a href="https://metamask.io" class="underline">here</a></p>
                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last"><img
                        class="rounded-lg shadow-xl" src="https://i.imgur.com/OQ7ShIt.png" alt="iLf6x03"></div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            INSTALLING</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Installing metamask</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">Installing metamask is fast and easy.
                            Head over to <a href="https://metamask.io" class="underline"> Metamask.io</a> and press the
                            "Download now" button. Metamask will now be installed as an extension to your browser. After
                            installing metamask, follow the setup instructions in Metamask.</p>
                    </div>
                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12"><img class="rounded-lg shadow-xl"
                                                                                src="https://i.imgur.com/jabbaxi.png"
                                                                                alt="nxlImvN"></div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">CONNECT
                            TO BSC</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Connect to the BSC Network</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">In order to use the BSC Network through
                            metamask, you need to add it as a network. Open up metamask and go to <b>Settings</b>, <i>scroll
                                down</i> and select
                            <b>Networks</b>. <br/>
                            <b>Now enter the following details:</b> <br/>
                            <b>Network name:</b> {{ d.name }} <br/>
                            <b>New RPC URL:</b> {{ d.rpc }} <br/>
                            <b>Chain ID:</b> {{ d.id }} <br/>
                            <b>Currency Symbol:</b> {{d.symbol}} <br/>
                            <b>Block Explorer URL:</b> {{ d.blockexplorer }} <br/>
                            Click save and navigate back to metamask. On top of the metamask window click the dropdown
                            and select "Binance Smart Chain Mainnet"
                        </p>
                        <div class="mt-2">
                            <label id="listbox-label" class="sr-only">
                                Change network
                            </label>
                            <div class="relative" v-click-outside="close">
                                <div class="inline-flex shadow-sm rounded-md divide-x divide-indigo-600">
                                    <div
                                        class="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-indigo-600">
                                        <div
                                            class="relative inline-flex items-center bg-indigo-500 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                                            <!-- Heroicon name: solid/check -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                                 fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd"
                                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                      clip-rule="evenodd"/>
                                            </svg>
                                            <p class="ml-2.5 text-sm font-medium capitalize">
                                                {{selected}}
                                            </p>
                                        </div>
                                        <button type="button"
                                                class="relative inline-flex items-center bg-indigo-500 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus:z-10"
                                                aria-haspopup="listbox" aria-expanded="true"
                                                aria-labelledby="listbox-label"
                                                @click="open = !open"
                                        >
                                            <span class="sr-only">Change network</span>
                                            <!-- Heroicon name: solid/chevron-down -->
                                            <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd"
                                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                      clip-rule="evenodd"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <!--
                                  Select popover, show/hide based on select state.

                                  Entering: ""
                                    From: ""
                                    To: ""
                                  Leaving: "transition ease-in duration-100"
                                    From: "opacity-100"
                                    To: "opacity-0"
                                -->
                                <ul class="left-0 absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    v-if="open"

                                >
                                    <!--
                                      Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

                                      Highlighted: "text-white bg-indigo-500", Not Highlighted: "text-gray-900"
                                    -->
                                    <li class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100" @click="select('mainnet')">
                                        <div class="flex flex-col">
                                            <div class="flex justify-between">
                                                <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                                                <p class="font-normal">
                                                    Main net
                                                </p>
                                                <!--
                                                  Checkmark, only display for selected option.

                                                  Highlighted: "text-white", Not Highlighted: "text-indigo-500"
                                                -->
                                                <span class="text-indigo-500" v-if="selected === 'mainnet'">
                                                  <!-- Heroicon name: solid/check -->
                                                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                                       aria-hidden="true">
                                                    <path fill-rule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clip-rule="evenodd"/>
                                                  </svg>
                                                </span>
                                            </div>
                                            <!-- Highlighted: "text-indigo-200", Not Highlighted: "text-gray-500" -->
                                            <p class="text-gray-500 mt-2">
                                                Show mainnet connection info
                                            </p>
                                        </div>
                                    </li>
                                    <li class="text-gray-900 cursor-pointer select-none relative p-4 text-sm hover:bg-gray-100" @click="select('testnet')">
                                        <div class="flex flex-col">
                                            <div class="flex justify-between">
                                                <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                                                <p class="font-normal">
                                                    Test net
                                                </p>
                                                <!--
                                                  Checkmark, only display for selected option.

                                                  Highlighted: "text-white", Not Highlighted: "text-indigo-500"
                                                -->
                                                <span class="text-indigo-500" v-if="selected === 'testnet'">
                                                  <!-- Heroicon name: solid/check -->
                                                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                                       aria-hidden="true">
                                                    <path fill-rule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clip-rule="evenodd"/>
                                                  </svg>
                                                </span>
                                            </div>
                                            <!-- Highlighted: "text-indigo-200", Not Highlighted: "text-gray-500" -->
                                            <p class="text-gray-500 mt-2">
                                                Show testnet connection info
                                            </p>
                                        </div>
                                    </li>

                                    <!-- More items... -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last"><img
                        class="rounded-lg shadow-xl" src="https://i.imgur.com/pT2rLoU.png" alt="iLf6x03"></div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">FUNDS</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Adding/sending funds to metamask</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">To add funds to metamask, you need to
                            know your address. This address is a specific BEP20 address, meaning all tokens on the BSC
                            Network (BEP20) can be sent to the same address. To find your address, simply open up
                            metamask and click on your address just below the top. This will copy your address. You can
                            now use that address as a withdrawal address from Binance or any other site, where you
                            withdraw BEP20 tokens. <br/><i> Adding funds is easy, but make sure the token you're trying
                                to deposit is a BEP20 tokens. Any tokens sent to the address that is not a BEP20 token
                                WILL be lost.</i></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import MiddleBanner from './MiddleBanner';

export default {
    name: 'MetaMask',
    components: { MiddleBanner },
    computed: {
        d: {
            get() {
                return this[this.selected];
            },
        },
    },
    data() {
        return {
            selected: 'mainnet',
            open: false,
            mainnet: {
                name: 'Binance Smart Chain Mainnet',
                rpc: 'https://bsc-dataseed.binance.org',
                id: 56,
                symbol: 'BNB',
                blockexplorer: 'https://bscscan.com/',
            },
            testnet: {
                name: 'Binance Smart Chain Testnet',
                rpc: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
                id: 97,
                symbol: 'BNB',
                blockexplorer: 'https://testnet.bscscan.com/',
            },
        };
    },
    methods: {
        close() {
            this.open = false;
        },
        select(v) {
            this.selected = v;
            this.close();
        },
    },
};
</script>

<style scoped>

</style>
